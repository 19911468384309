<template>
  <div class="container">
    <div class="login-container">
      <div class="logo-box">
        <div class="img-box">
          <img src="https://pakke-landing-page-assets.s3.us-west-1.amazonaws.com/pakke_logo_c71ce5707a.png">
        </div>
      </div>

      <div class="title">¡Bienvenido a Pakke!</div>
      <div class="subtitle">¡Todo lo que necesitas para tus envíos, en un sólo lugar!</div>

      <div class="card">
        <div class="card-title">
          Conecta tu ecommerce
        </div>

        <div class="card-subtitle">
          Para finalizar la instalación de tu tienda inicia sesión.
        </div>

        <div class="button-row">
          <a v-bind:href="loginLink">Iniciar sesión</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "ShopifyLogin",
  props: {
    link: {
      type: String,
      default: 'https://seller.pakke.mx/login'
    }
  },
  data() {
    return {
      loginLink: this.link,
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    const shop = params.get("shop");
    console.log(shop);
    const env = params.get("env");
    switch (env) {
      case 'es-seller':
        this.loginLink = 'https://seller.pakke.es/login';
        break;
      case 'mx-full360':
        this.loginLink = 'https://seller.full360.mx/login';
        break;
      default:
        this.loginLink = 'https://seller.pakke.mx/login';
        break;
    }
    this.loginLink = shop ? (this.loginLink + '?shop=' + shop) : this.loginLink;
  },
}
</script>

<style scoped>

.button-row {
  display: flex;
  justify-content: center;
}

a {
  background: #eb3524;
  border-radius: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 12px 22px;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.card-title {
  color: #4f4f4f;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-subtitle {
  color: #4f4f4f;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}

.title {
  color: #2a326d;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.subtitle {
  color: #4f4f4f;
  font-size: 15px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 60px;
}

.card {
  box-shadow: 0 2px 8px 5px rgb(6 23 42 / 7%);
  border-radius: 20px;
  background: white;
  padding: 25px;
  max-width: 450px;
  width: 90%;
}

.container {
  background-image: url("https://www.pakke.com/assets/img/bg-homegood_edited_edited.2ee46347.webp");
  background-color: #f5f5fb;
  background-size: cover;
  background-position: 50%;
  height: 100vh;
  width: 100vw;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 20px;
}

.logo-box {
  width: 180px;
  margin-bottom: 35px;
}

.img-box img {
  width: 100%;
  height: auto;
}
</style>
